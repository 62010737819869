
    .genre-prop {
        margin-bottom: $gap*3;

         img{
        	margin: 0 auto;
        	display:block;
        }
    }

    .growth-stages__heading {
        color: $blue-base;
        font-family: $font-family-heading;
        font-size: $font-size-lg;
        margin: 0 0 $gap;

        & + p {
            font-size: $font-size-md;
        }

        @include screen-xs {
             font-size: $font-size-lg + 10px;
        }
    }

    .box-genre-prop {
         margin-top: $gap;
         position: relative;
         margin-bottom: 0px;
         align-items: center;
    	 display: flex;

         &.align-start{
            align-items: start;
         }

         


         @include screen-xs {
            padding-left: 140px;
			min-height:140px;
         }

         &.no-pad{
         	padding-left:0px;
         }

        & +  .box-genre-prop {
           
        }

        h2,
        h3 {
            color: $blue-base;
            font-size: $font-size-md;
            font-family: $font-family-base-bold;
            margin: 0 0 $gap - 5px;
            text-transform: none;
        }
        ul{
        	padding-left: 20px;
        	margin-top:0px;
        }


        .timespan {
            display: block;
            font-size: $font-size-sm;
            margin-bottom: $gap;
        }

        @include screen-xs {

            &:after {
                content: "";
                position: absolute;
                left: 0;
                top: auto;
                bottom: auto;
            }

            &.prop-1 {
            	
                &:after {
                    @include sprite($icon-sex);
                }
            }

            &.prop-2 {
            	
                &:after {
                    @include sprite($icon-icon-boygirl);
                }
            }

            &.prop-3 {
            	
                &:after {
                    @include sprite($icon-notepad);
                }
            }

            &.prop-4 {
            	
                &:after {
                    @include sprite($icon-bone);
                }
            }

            &.prop-5 {
            	
                &:after {
                    @include sprite($icon-babyheart);
                }
            }

            &.prop-6 {
            	
                &:after {
                    @include sprite($icon-bike);
                }
            }

            &.prop-7 {
            	
                &:after {
                    @include sprite($icon-food);
                }
            }

            &.prop-8 {
            	
                &:after {
                    @include sprite($icon-nature);
                }
            }

            &.brain{

                &:after {
                    @include sprite($icon-brain);
                }
            }  

            &.igf{

                &:after {
                    @include sprite($icon-igf);
                }
            }
            &.man{

                &:after {
                    @include sprite($icon-boy);
                }
            }
            &.calendar2{

                &:after {
                    @include sprite($icon-calendar2);
                }
            }
            &.clock{

                &:after {
                    @include sprite($icon-clock);
                }
            }


        

        }

        &.left-prop{

    	 	display: block;

         	@include screen-xs {
            padding-left: 0px;
            padding-right: 360px;


            	&:after {
                content: "";
                position: absolute;
                left: auto;
                right:0;
                top: 0;
                @include sprite($icon-parrents);
            	}

        	}

         }


    }



        .boxes-wrap {
        	padding-top: $gap;
           .boxes-item {
            text-align: center;
            max-width: 100%;
            margin: 0 auto;

            position: relative;

            @include screen-xs {
                max-width: 480px;
            }

            .box__heading{

            }

            p {
                margin: $gap*2 auto;
                max-width: 400px;

                &:first-child {
                    margin-top: 0;
                }

                &.slim-content {

                    @include screen-xs {
                        max-width: 250px;
                    }
                }

                &.with-link {
                    text-align: center;
                    margin-bottom: 0;
                }

            }

            &.with-figure {

                @include screen-xs {

                    &:after {
                        display: block;
                        content: "";
                        position: absolute;
                        top: -40px;
                        right: -$gap;
                        width: 209px;
                        height: 389px;
                        background: url(#{$bg-url}figure.png) no-repeat 0 0;
                    }

                }

            }
           
            &.with-icon {
                 padding-top: 150px;

                 &:before {
                    display: block;
                    content: "";
                    @include center(x);
                    top: 0;
                 }
            }

           

             &.icon-num1 {

                &:before {
                    @include sprite($icon-num1);
                }

            }
            &.icon-num2 {

                &:before {
                    @include sprite($icon-num2);
                }

            }
            &.icon-num3 {

                &:before {
                    @include sprite($icon-num3);
                }

            }

        }
        }

