
	table {


		th,
		td {

		}

		th {

		}

		thead {
			
		}

		tbody {

			tr {

				&:nth-child(even) {
				
				}

			}

		}

	}

	.table-container {


	}