
	.page-header {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 888;
        background-color: $light-base;
        transition: background-color .3s ease;

        @include screen-sm {
            top: $gap;
            background-color: transparent;
        }

        .container-fluid {
            position: relative;
            padding-top: $gap - 5px;
            padding-bottom: $gap - 5px;
            transition: width .2s ease;

            @include screen-sm {
                padding-top: $gap*2;
                padding-bottom: $gap*2;
            }

        }

        &.is-sticky {
            background-color: $light-base;

            @include screen-sm {

                .container-fluid {
                    padding-top: $gap;
                    padding-bottom: $gap;
                }

            }

        }
			
	}

