
	.page-footer {
        background-color: $gray-dark;
        text-align: center;

        .container-fluid {
            padding-bottom: $gap*2;

            @media only screen and (max-width: 768px) {
                padding-top: $gap*2;
            }

        }
			
	}

