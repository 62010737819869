
	@font-face {
	    font-family: 'amatic_scbold';
	    src: url('#{$fonts-url}amaticsc-bold-webfont.woff2') format('woff2'),
	         url('#{$fonts-url}amaticsc-bold-webfont.woff') format('woff');
	    font-weight: normal;
	    font-style: normal;
	}

	@font-face {
	    font-family: 'amatic_scregular';
	    src: url('#{$fonts-url}amaticsc-regular-webfont.woff2') format('woff2'),
	         url('#{$fonts-url}amaticsc-regular-webfont.woff') format('woff');
	    font-weight: normal;
	    font-style: normal;
	}

	@font-face {
	    font-family: 'quicksandbold';
	    src: url('#{$fonts-url}quicksand-bold-webfont.woff2') format('woff2'),
	         url('#{$fonts-url}quicksand-bold-webfont.woff') format('woff');
	    font-weight: normal;
	    font-style: normal;
	}

	@font-face {
	    font-family: 'quicksandlight';
	    src: url('#{$fonts-url}quicksand-light-webfont.woff2') format('woff2'),
	         url('#{$fonts-url}quicksand-light-webfont.woff') format('woff');
	    font-weight: normal;
	    font-style: normal;
	}

	@font-face {
	    font-family: 'quicksandmedium';
	    src: url('#{$fonts-url}quicksand-medium-webfont.woff2') format('woff2'),
	         url('#{$fonts-url}quicksand-medium-webfont.woff') format('woff');
	    font-weight: normal;
	    font-style: normal;
	}


	@font-face {
	    font-family: 'quicksandregular';
	    src: url('#{$fonts-url}quicksand-regular-webfont.woff2') format('woff2'),
	         url('#{$fonts-url}quicksand-regular-webfont.woff') format('woff');
	    font-weight: normal;
	    font-style: normal;
	}
