
	.hp-section {

        .container-fluid {
            padding-top: $gap*4;
            padding-bottom: $gap*4;
        }

        p {
            color: $light-base;

            a {
                color: $light-base;
            }

        }

        /*  Header
        -------------------------------------------------------------------------- */

        header {
            text-align: center;
            margin: 0 0 $gap*4;

            p {
                font-size: $font-size-sm;
                max-width: 730px;
                margin: 0 auto;

                @include screen-sm {
                    font-size: $font-size-md;
                }

            }
        }

        /*  Box
        -------------------------------------------------------------------------- */

        .box {
            text-align: center;
            max-width: 100%;
            margin: 0 auto;
            position: relative;

            @include screen-xs {
                max-width: 480px;
            }

            p {
                margin: $gap*2 auto;
                max-width: 400px;

                &:first-child {
                    margin-top: 0;
                }

                &.slim-content {

                    @include screen-xs {
                        max-width: 250px;
                    }
                }

                &.with-link {
                    text-align: center;
                    margin-bottom: 0;
                }

            }

            &.with-figure {

                @include screen-xs {

                    &:after {
                        display: block;
                        content: "";
                        position: absolute;
                        top: -40px;
                        right: -$gap;
                        width: 209px;
                        height: 389px;
                        background: url(#{$bg-url}figure.png) no-repeat 0 0;
                    }

                }

            }
           
            &.with-icon {
                 padding-top: 150px;

                 &:before {
                    display: block;
                    content: "";
                    @include center(x);
                    top: 0;
                 }
            }

            &.icon-book-lg {

                &:before {
                    @include sprite($icon-book-lg);
                }

            }

            &.icon-pen-lg {

                &:before {
                    @include sprite($icon-pen-lg);
                }

            }

            &.icon-hand-lg {

                &:before {
                    @include sprite($icon-hand-lg);
                }

            }

            &.icon-needle-lg {

                &:before {
                    @include sprite($icon-needle-lg);
                }

            }

             &.icon-num1 {

                &:before {
                    @include sprite($icon-num1);
                }

            }
            &.icon-num2 {

                &:before {
                    @include sprite($icon-num2);
                }

            }
            &.icon-num3 {

                &:before {
                    @include sprite($icon-num3);
                }

            }


        }

        /*  Box heading
        -------------------------------------------------------------------------- */

        .box__heading {
            text-transform: uppercase;
            color: $light-base;
            font-size: $font-size-md;
            margin: 0 0 0 $gap;
        }

	}

/* ==========================================================================
   Section: .intro
   ========================================================================== */

   .intro {
        background-color: $light-base;

        .container-fluid {
            position: relative;
            padding-top: $gap*8;

            @include screen-xs {
                padding-top: $gap*10;
            }

            @include screen-sm {
                padding-top: $gap*8;
                padding-bottom: $gap*16;
            }
        }

        @include screen-sm {
            background: url(#{$bg-url}intro-bg.png) no-repeat 50% 100%;
            background-size: cover;
            padding-bottom: 70px;
            padding-top: 160px;
            position: relative;
            z-index: 666;
            margin-bottom: -100px;
        }

        .hp-section-main-heading {
            color: $pink-base;
        }

        p {
            color: $text-color;
            font-size: $font-size-md;
        }

   }

    .intro__content {
        max-width: 520px;
        text-align: center;

        @media only screen and (max-width: 778px) {
           margin: 0 auto;
           max-width: 100%;
        }

        p {
            &:last-child {
                margin-bottom: 0;
            }
        }

    }

/* ==========================================================================
   Section: .diagnosis-problem
   ========================================================================== */

    .diagnosis-problem {
        background-color: $blue-base;

        .container-fluid {
            position: relative;
        }

        @include screen-sm {
            background: transparent url(#{$bg-url}section-blue-bg.png) no-repeat 50% 100%;
            background-size: cover;
            padding-top: 90px;
            padding-bottom: 70px;
            margin-bottom: -100px;
            position: relative;
            z-index: 555;
        }

        [class*="col-"] + [class*="col-"] {

            @media only screen and (max-width: 778px) {
                border-top: 2px solid $blue-separator;
                margin-top: $gap*2;
                padding-top: $gap*2;
            }

        }

    }

/* ==========================================================================
   Section: .problem-solution
   ========================================================================== */

      .problem-solution {
        background-color: $orange-base;

        @include screen-sm {
            background: $orange-base url(#{$bg-url}section-orange-bg.png) no-repeat 50% 100%;
            background-size: 100% auto;
            padding-bottom: 70px;
            padding-top: 90px;
        }

        [class*="col-"] + [class*="col-"] {

            @media only screen and (max-width: 778px) {
                border-top: 2px solid $orange-separator;
                margin-top: $gap*2;
                padding-top: $gap*2;
            }

        }

    } 