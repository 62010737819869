
/*	==========================================================================
   Paths
   ========================================================================== */

	$base-url: 					"../images/";
	$bg-url: 					"../images/bg/";
   $ilu-url:               "../images/ilu/";
	$ico-url: 					"../images/ico/";
	$fonts-url: 				"../fonts/";

/* ==========================================================================
   Colors
   ========================================================================== */

   $subpage-bg:            #DDE5EA;

   $light-base:            #fff;
   $gray-base:             #3D3D3D;
   $gray-dark:             #D9D9D9;
   $gray-light:            #F2F3F4;
   $pink-base:             #d90368;
   $blue-base:             #2274a5;
   $blue-separator:        #2583BA;
   $orange-base:           #FD7700;
   $orange-separator:      #FF8B22;
   $orange-text:           #fe841a;

   $html-bg:               #DDE5EA;
   $body-bg:               #DDE5EA;

   $text-color:            $gray-base;

   $link-color:            $gray-base;
   $link-color-hover:      $blue-base;
   
/*	==========================================================================
   Typo
   ========================================================================== */

	$font-size-base: 			62.5%;
	$font-size-xs: 			12px;
	$font-size-sm: 			16px;
	$font-size-md: 			20px;
	$font-size-lg: 			40px;
	$font-size-xl: 			60px;
   $font-size-note:        14px;

	$line-height-base: 		100%;

   $font-family-heading:        'amatic_scregular', sans-serif;
   $font-family-heading-bold:   'amatic_scbold', sans-serif;

	$font-family-base: 		     'quicksandregular', sans-serif;
   $font-family-base-light:     'quicksandlight', sans-serif;
   $font-family-base-medium:    'quicksandmedium', sans-serif;
   $font-family-base-bold:      'quicksandbold', sans-serif;

/*	==========================================================================
   Misc
   ========================================================================== */

   $gap:                   15px;
   $button-border-radius:  25px;


