
	.tabs {
        margin: $gap*3 0px $gap*3 0px;
        font-family: $font-family-base-bold;
        max-width: 100%;

        @include screen-md {
            max-width: 660px;
        }

        ul {
            @extend %unstyled-list;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: stretch;
            flex-wrap: wrap;

            @include screen-xs {
                flex-direction: row;
                flex-wrap: nowrap;
            }
        }

        li {
            background: #fff;
            border-radius: 5px;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: background-color 0.3s ease;

            @include screen-xs {
                flex: auto;
            }

            & + li {
                margin-left: 0;
                margin-top: 2px;
               

                @include screen-xs {
                    margin-top: 0;
                    margin-left: 2px;
                }

            }

            &:hover,
            &.is-active {
                background-color: $blue-base;
                position: relative;

                @include screen-xs {
                    &:after {
                        display: block;
                        content: "";
                        @include sprite($icon-active-tab);
                        position: absolute;
                        top: -13px;
                    }
                }

                a {
                    color: $light-base;
                }
            }

        }

        a {
            display: block;
            width: 100%;
            padding: $gap + 10px $gap;
            text-decoration: none;
            text-transform: uppercase;
            text-align: center;
        }

	}