
	.overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 444;
        background: rgba(217, 217, 217, 0.7);

	}



