
	.ico {
		position: relative;
		outline: 0;
		
		&:before {
			display: block;
			position: absolute;
			content: "";
		}


		/*	Misc
		-------------------------------------------------------------------------- */


	}

