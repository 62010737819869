
	.arrow-scroll-down {
        display: none;

        @include screen-md {
            display: block;
            margin: 0 auto;
            width: 66px;
            height: 64px;
            background: url(#{$bg-url}arrow-scroll-down.png) no-repeat 0 0;
            position: absolute;
            left: 50%;
            margin-left: -33px;
            z-index: 555;
            transition: transform .5s ease;
        }

        .diagnosis-problem & {
             bottom: -65px;
        }

        .intro & {
             bottom: -100px;
        }

        &:hover {
            @include rotate(360);
        }

	}