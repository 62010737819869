.table-image{

	background: $light-base;
	margin-bottom: $gap*2;
    
			h3{
			text-align:center;
			font-size: $font-size-md;
			padding: $gap;
			font-family: $font-family-base-bold;
			}


	.heading{

		background: $blue-base;
		color: $light-base;
		text-align:center;

			h3{
			font-size: $font-size-md;
			padding: $gap;
			}
	}
	.content{
		padding: $gap + 5;

		img{
			margin-bottom: $gap + 5;


		}

		div{
			&:last-of-type{

				img{
					margin-bottom:0px;
				}
				
			}
		}
	}
}