
    $accordion-item-padding:    60px;

    .accordion {
        margin: 0 0 $gap*3 0;
    }

    .accordion__heading {
        padding: $gap 0;
        margin: 0;
        cursor: pointer;
        position: relative;

        &:hover {

            .icon-accordion-item {
                &:after {
                     transform: rotate(90deg);
                }
            }

        }
    }

    .icon-accordion-item {
        @include sprite($icon-accordion);
        @include center(y);
        left: -$accordion-item-padding;

        &:before {
            content: "";
            height: 4px;
            width: 12px;
            background-color: $pink-base;
            position: absolute;
            top: 50%;
            left: 50%;
            margin: -2px 0 0 -6px;
        }

        &:after {
            display: none;
        }

        &:before,
        &:after {
            transition: all 0.1s ease-out;
        }

        .is-closed & {

            &:after {
                display: block;
                content: "";
                height: 12px;
                width: 4px;
                background-color: $pink-base;
                position: absolute;
                top: 50%;
                left: 50%;
                margin: -6px 0 0 -2px;
            }

        }
    }

    .accordion__item {
        background: url(#{$bg-url}accordion-item-underline.png) no-repeat 50% 100%;
        background-size: 100% auto;
        padding-left: $accordion-item-padding;
        padding-bottom: 10px;

        &.content_block{
            padding-left:0px;

            .accordion__heading{
                margin-left: $accordion-item-padding;
            }
        }

        &.is-closed {

            .accordion__text {
                display: none;
            }

        }

        &.small-line{

            .accordion__text{
            background: url(../images/bg/accordion-item-underline.png) no-repeat 0% 100%;
            background-size: 75% auto;
           
            padding-bottom: 40px;
            margin-bottom: 40px;
            margin-left: -$accordion-item-padding;

            &:last-of-type{
                margin-bottom:0px;
                background:none;
            }
            }
        }

    }

    .accordion__text {

        p {
            margin: 0;
            padding: 0 0 $gap;

            &.genre__subtitle{
                padding: $gap 0 ;
            }
        }
        
    }



