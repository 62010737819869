
    .growth-stages {
        margin: $gap*3 0px $gap 0px;
    }

    .growth-stages__heading {
        color: $blue-base;
        font-family: $font-family-heading;
        font-size: $font-size-lg;
        margin: 0 0 $gap;

        & + p {
            font-size: $font-size-md;
        }

        @include screen-xs {
             font-size: $font-size-lg + 10px;
        }
    }

    .box-growth-stage {
         margin-top: $gap*2;
         position: relative;

         @include screen-xs {
            padding-left: 140px;
         }


           &.align-center{
             display:flex;
             align-items: center;
             min-height: 124px;
           }


        & +  .box-growth-stage {

        }

        h2,
        h3 {
            color: $blue-base;
            font-size: $font-size-md;
            font-family: $font-family-base-bold;
            margin: 0 0 $gap - 5px;
            text-transform: none;
        }


        &.icon-num3{
            h2{
                color:$gray-base;
            }
        }
        &.icon-num1,&.icon-num2,&.icon-check-pink{
            h2{
                color:$gray-base;
            }
            a{
                color:$pink-base;
            }
        }
        .timespan {
            display: block;
            font-size: $font-size-sm;
            margin-bottom: $gap;
        }

        @include screen-xs {

            &:after {
                content: "";
                position: absolute;
                left: 0;
                top: 0;
            }

            &.stage-1 {
                &:after {
                    @include sprite($icon-stage-1);
                }
            }

            &.stage-2 {
                &:after {
                    @include sprite($icon-stage-2);
                }
            }

             &.stage-3 {
                &:after {
                    @include sprite($icon-stage-3);
                }
            }

            &.check {
                padding-left:100px;

                h2{
                    color:$gray-base;
                }
                &:after {
                    @include sprite($icon-check-pink);
                }
            }

            &.check-green {
                padding-left:100px;

                h2{
                    color:$gray-base;
                }
                &:after {
                    content:url("../images/icons/icon-check-green.png");
                }
            }

            &.check-allert {
                padding-left:100px;

                h2{
                    color:$gray-base;
                }
                &:after {
                    content:url("../images/icons/allert.png");
                }
            }

             &.icon-num1 {


                h2{
                    color:$gray-base;
                }
                a{
                    color: $pink-base
                }
                &:after {
                    @include sprite($icon-num1);
                }
            }

             &.icon-num2 {


                h2{
                    color:$gray-base;
                }
                a{
                    color: $pink-base
                }
                &:after {
                    @include sprite($icon-num2);
                }
            }

             &.icon-num3 {


                h2{
                    color:$gray-base;
                }


                &:after {
                    @include sprite($icon-num3);
                }
            }

            &.pediatr {


                h2{
                    color:$orange-base;
                }


                &:after {
                    @include sprite($icon-pediatr);
                }
            }

            &.heart-plus {


                h2{
                    color:$blue-base;
                }


                &:after {
                    @include sprite($icon-heart-plus);
                }
            }


        }

        .bg-size{

            padding-right: 110px;
            position:relative;
            min-height: 255px;
            &:after {
                    content: url(../images/content/size-1a.jpg);
                    position:absolute;
                    top:0px;
                    display:inline-block;
                    width: 95px;
                    height:auto;
                    right:0px;
                }
        }

    }
