// SCSS variables are information about icon's compiled state, stored under its original file name
//
// .icon-home {
//   width: $icon-home-width;
// }
//
// The large array-like variables contain all information about a single icon
// $icon-home: x y offset_x offset_y width height total_width total_height image_path;
//
// At the bottom of this section, we provide information about the spritesheet itself
// $spritesheet: width height image $spritesheet-sprites;
$icon-18-name: 'icon-18';
$icon-18-x: 560px;
$icon-18-y: 461px;
$icon-18-offset-x: -560px;
$icon-18-offset-y: -461px;
$icon-18-width: 47px;
$icon-18-height: 47px;
$icon-18-total-width: 781px;
$icon-18-total-height: 709px;
$icon-18-image: '../images/icons/sprite.png';
$icon-18: (560px, 461px, -560px, -461px, 47px, 47px, 781px, 709px, '../images/icons/sprite.png', 'icon-18', );
$icon-accordion-name: 'icon-accordion';
$icon-accordion-x: 557px;
$icon-accordion-y: 425px;
$icon-accordion-offset-x: -557px;
$icon-accordion-offset-y: -425px;
$icon-accordion-width: 37px;
$icon-accordion-height: 35px;
$icon-accordion-total-width: 781px;
$icon-accordion-total-height: 709px;
$icon-accordion-image: '../images/icons/sprite.png';
$icon-accordion: (557px, 425px, -557px, -425px, 37px, 35px, 781px, 709px, '../images/icons/sprite.png', 'icon-accordion', );
$icon-active-tab-name: 'icon-active-tab';
$icon-active-tab-x: 594px;
$icon-active-tab-y: 425px;
$icon-active-tab-offset-x: -594px;
$icon-active-tab-offset-y: -425px;
$icon-active-tab-width: 37px;
$icon-active-tab-height: 35px;
$icon-active-tab-total-width: 781px;
$icon-active-tab-total-height: 709px;
$icon-active-tab-image: '../images/icons/sprite.png';
$icon-active-tab: (594px, 425px, -594px, -425px, 37px, 35px, 781px, 709px, '../images/icons/sprite.png', 'icon-active-tab', );
$icon-arrow-down-blue-name: 'icon-arrow-down-blue';
$icon-arrow-down-blue-x: 604px;
$icon-arrow-down-blue-y: 508px;
$icon-arrow-down-blue-offset-x: -604px;
$icon-arrow-down-blue-offset-y: -508px;
$icon-arrow-down-blue-width: 44px;
$icon-arrow-down-blue-height: 43px;
$icon-arrow-down-blue-total-width: 781px;
$icon-arrow-down-blue-total-height: 709px;
$icon-arrow-down-blue-image: '../images/icons/sprite.png';
$icon-arrow-down-blue: (604px, 508px, -604px, -508px, 44px, 43px, 781px, 709px, '../images/icons/sprite.png', 'icon-arrow-down-blue', );
$icon-arrow-down-name: 'icon-arrow-down';
$icon-arrow-down-x: 560px;
$icon-arrow-down-y: 585px;
$icon-arrow-down-offset-x: -560px;
$icon-arrow-down-offset-y: -585px;
$icon-arrow-down-width: 44px;
$icon-arrow-down-height: 43px;
$icon-arrow-down-total-width: 781px;
$icon-arrow-down-total-height: 709px;
$icon-arrow-down-image: '../images/icons/sprite.png';
$icon-arrow-down: (560px, 585px, -560px, -585px, 44px, 43px, 781px, 709px, '../images/icons/sprite.png', 'icon-arrow-down', );
$icon-arrow-right-name: 'icon-arrow-right';
$icon-arrow-right-x: 669px;
$icon-arrow-right-y: 569px;
$icon-arrow-right-offset-x: -669px;
$icon-arrow-right-offset-y: -569px;
$icon-arrow-right-width: 14px;
$icon-arrow-right-height: 13px;
$icon-arrow-right-total-width: 781px;
$icon-arrow-right-total-height: 709px;
$icon-arrow-right-image: '../images/icons/sprite.png';
$icon-arrow-right: (669px, 569px, -669px, -569px, 14px, 13px, 781px, 709px, '../images/icons/sprite.png', 'icon-arrow-right', );
$icon-babyheart-name: 'icon-babyheart';
$icon-babyheart-x: 112px;
$icon-babyheart-y: 337px;
$icon-babyheart-offset-x: -112px;
$icon-babyheart-offset-y: -337px;
$icon-babyheart-width: 112px;
$icon-babyheart-height: 124px;
$icon-babyheart-total-width: 781px;
$icon-babyheart-total-height: 709px;
$icon-babyheart-image: '../images/icons/sprite.png';
$icon-babyheart: (112px, 337px, -112px, -337px, 112px, 124px, 781px, 709px, '../images/icons/sprite.png', 'icon-babyheart', );
$icon-ball-name: 'icon-ball';
$icon-ball-x: 560px;
$icon-ball-y: 508px;
$icon-ball-offset-x: -560px;
$icon-ball-offset-y: -508px;
$icon-ball-width: 44px;
$icon-ball-height: 44px;
$icon-ball-total-width: 781px;
$icon-ball-total-height: 709px;
$icon-ball-image: '../images/icons/sprite.png';
$icon-ball: (560px, 508px, -560px, -508px, 44px, 44px, 781px, 709px, '../images/icons/sprite.png', 'icon-ball', );
$icon-bike-name: 'icon-bike';
$icon-bike-x: 336px;
$icon-bike-y: 337px;
$icon-bike-offset-x: -336px;
$icon-bike-offset-y: -337px;
$icon-bike-width: 112px;
$icon-bike-height: 124px;
$icon-bike-total-width: 781px;
$icon-bike-total-height: 709px;
$icon-bike-image: '../images/icons/sprite.png';
$icon-bike: (336px, 337px, -336px, -337px, 112px, 124px, 781px, 709px, '../images/icons/sprite.png', 'icon-bike', );
$icon-bone-name: 'icon-bone';
$icon-bone-x: 557px;
$icon-bone-y: 0px;
$icon-bone-offset-x: -557px;
$icon-bone-offset-y: 0px;
$icon-bone-width: 112px;
$icon-bone-height: 124px;
$icon-bone-total-width: 781px;
$icon-bone-total-height: 709px;
$icon-bone-image: '../images/icons/sprite.png';
$icon-bone: (557px, 0px, -557px, 0px, 112px, 124px, 781px, 709px, '../images/icons/sprite.png', 'icon-bone', );
$icon-book-lg-name: 'icon-book-lg';
$icon-book-lg-x: 557px;
$icon-book-lg-y: 124px;
$icon-book-lg-offset-x: -557px;
$icon-book-lg-offset-y: -124px;
$icon-book-lg-width: 112px;
$icon-book-lg-height: 124px;
$icon-book-lg-total-width: 781px;
$icon-book-lg-total-height: 709px;
$icon-book-lg-image: '../images/icons/sprite.png';
$icon-book-lg: (557px, 124px, -557px, -124px, 112px, 124px, 781px, 709px, '../images/icons/sprite.png', 'icon-book-lg', );
$icon-boy-name: 'icon-boy';
$icon-boy-x: 557px;
$icon-boy-y: 248px;
$icon-boy-offset-x: -557px;
$icon-boy-offset-y: -248px;
$icon-boy-width: 112px;
$icon-boy-height: 124px;
$icon-boy-total-width: 781px;
$icon-boy-total-height: 709px;
$icon-boy-image: '../images/icons/sprite.png';
$icon-boy: (557px, 248px, -557px, -248px, 112px, 124px, 781px, 709px, '../images/icons/sprite.png', 'icon-boy', );
$icon-brain-small-name: 'icon-brain-small';
$icon-brain-small-x: 735px;
$icon-brain-small-y: 496px;
$icon-brain-small-offset-x: -735px;
$icon-brain-small-offset-y: -496px;
$icon-brain-small-width: 42px;
$icon-brain-small-height: 47px;
$icon-brain-small-total-width: 781px;
$icon-brain-small-total-height: 709px;
$icon-brain-small-image: '../images/icons/sprite.png';
$icon-brain-small: (735px, 496px, -735px, -496px, 42px, 47px, 781px, 709px, '../images/icons/sprite.png', 'icon-brain-small', );
$icon-brain-name: 'icon-brain';
$icon-brain-x: 112px;
$icon-brain-y: 461px;
$icon-brain-offset-x: -112px;
$icon-brain-offset-y: -461px;
$icon-brain-width: 112px;
$icon-brain-height: 124px;
$icon-brain-total-width: 781px;
$icon-brain-total-height: 709px;
$icon-brain-image: '../images/icons/sprite.png';
$icon-brain: (112px, 461px, -112px, -461px, 112px, 124px, 781px, 709px, '../images/icons/sprite.png', 'icon-brain', );
$icon-calendar-name: 'icon-calendar';
$icon-calendar-x: 496px;
$icon-calendar-y: 392px;
$icon-calendar-offset-x: -496px;
$icon-calendar-offset-y: -392px;
$icon-calendar-width: 52px;
$icon-calendar-height: 43px;
$icon-calendar-total-width: 781px;
$icon-calendar-total-height: 709px;
$icon-calendar-image: '../images/icons/sprite.png';
$icon-calendar: (496px, 392px, -496px, -392px, 52px, 43px, 781px, 709px, '../images/icons/sprite.png', 'icon-calendar', );
$icon-calendar2-name: 'icon-calendar2';
$icon-calendar2-x: 336px;
$icon-calendar2-y: 461px;
$icon-calendar2-offset-x: -336px;
$icon-calendar2-offset-y: -461px;
$icon-calendar2-width: 112px;
$icon-calendar2-height: 124px;
$icon-calendar2-total-width: 781px;
$icon-calendar2-total-height: 709px;
$icon-calendar2-image: '../images/icons/sprite.png';
$icon-calendar2: (336px, 461px, -336px, -461px, 112px, 124px, 781px, 709px, '../images/icons/sprite.png', 'icon-calendar2', );
$icon-check-pink-name: 'icon-check-pink';
$icon-check-pink-x: 669px;
$icon-check-pink-y: 496px;
$icon-check-pink-offset-x: -669px;
$icon-check-pink-offset-y: -496px;
$icon-check-pink-width: 66px;
$icon-check-pink-height: 73px;
$icon-check-pink-total-width: 781px;
$icon-check-pink-total-height: 709px;
$icon-check-pink-image: '../images/icons/sprite.png';
$icon-check-pink: (669px, 496px, -669px, -496px, 66px, 73px, 781px, 709px, '../images/icons/sprite.png', 'icon-check-pink', );
$icon-checklist-name: 'icon-checklist';
$icon-checklist-x: 500px;
$icon-checklist-y: 248px;
$icon-checklist-offset-x: -500px;
$icon-checklist-offset-y: -248px;
$icon-checklist-width: 55px;
$icon-checklist-height: 54px;
$icon-checklist-total-width: 781px;
$icon-checklist-total-height: 709px;
$icon-checklist-image: '../images/icons/sprite.png';
$icon-checklist: (500px, 248px, -500px, -248px, 55px, 54px, 781px, 709px, '../images/icons/sprite.png', 'icon-checklist', );
$icon-clock-name: 'icon-clock';
$icon-clock-x: 669px;
$icon-clock-y: 124px;
$icon-clock-offset-x: -669px;
$icon-clock-offset-y: -124px;
$icon-clock-width: 112px;
$icon-clock-height: 124px;
$icon-clock-total-width: 781px;
$icon-clock-total-height: 709px;
$icon-clock-image: '../images/icons/sprite.png';
$icon-clock: (669px, 124px, -669px, -124px, 112px, 124px, 781px, 709px, '../images/icons/sprite.png', 'icon-clock', );
$icon-food-name: 'icon-food';
$icon-food-x: 669px;
$icon-food-y: 248px;
$icon-food-offset-x: -669px;
$icon-food-offset-y: -248px;
$icon-food-width: 112px;
$icon-food-height: 124px;
$icon-food-total-width: 781px;
$icon-food-total-height: 709px;
$icon-food-image: '../images/icons/sprite.png';
$icon-food: (669px, 248px, -669px, -248px, 112px, 124px, 781px, 709px, '../images/icons/sprite.png', 'icon-food', );
$icon-growth-men-name: 'icon-growth-men';
$icon-growth-men-x: 448px;
$icon-growth-men-y: 337px;
$icon-growth-men-offset-x: -448px;
$icon-growth-men-offset-y: -337px;
$icon-growth-men-width: 49px;
$icon-growth-men-height: 55px;
$icon-growth-men-total-width: 781px;
$icon-growth-men-total-height: 709px;
$icon-growth-men-image: '../images/icons/sprite.png';
$icon-growth-men: (448px, 337px, -448px, -337px, 49px, 55px, 781px, 709px, '../images/icons/sprite.png', 'icon-growth-men', );
$icon-hand-lg-name: 'icon-hand-lg';
$icon-hand-lg-x: 0px;
$icon-hand-lg-y: 585px;
$icon-hand-lg-offset-x: 0px;
$icon-hand-lg-offset-y: -585px;
$icon-hand-lg-width: 112px;
$icon-hand-lg-height: 124px;
$icon-hand-lg-total-width: 781px;
$icon-hand-lg-total-height: 709px;
$icon-hand-lg-image: '../images/icons/sprite.png';
$icon-hand-lg: (0px, 585px, 0px, -585px, 112px, 124px, 781px, 709px, '../images/icons/sprite.png', 'icon-hand-lg', );
$icon-heart-plus-name: 'icon-heart-plus';
$icon-heart-plus-x: 112px;
$icon-heart-plus-y: 585px;
$icon-heart-plus-offset-x: -112px;
$icon-heart-plus-offset-y: -585px;
$icon-heart-plus-width: 112px;
$icon-heart-plus-height: 124px;
$icon-heart-plus-total-width: 781px;
$icon-heart-plus-total-height: 709px;
$icon-heart-plus-image: '../images/icons/sprite.png';
$icon-heart-plus: (112px, 585px, -112px, -585px, 112px, 124px, 781px, 709px, '../images/icons/sprite.png', 'icon-heart-plus', );
$icon-heart-name: 'icon-heart';
$icon-heart-x: 557px;
$icon-heart-y: 372px;
$icon-heart-offset-x: -557px;
$icon-heart-offset-y: -372px;
$icon-heart-width: 61px;
$icon-heart-height: 53px;
$icon-heart-total-width: 781px;
$icon-heart-total-height: 709px;
$icon-heart-image: '../images/icons/sprite.png';
$icon-heart: (557px, 372px, -557px, -372px, 61px, 53px, 781px, 709px, '../images/icons/sprite.png', 'icon-heart', );
$icon-icon-boygirl-name: 'icon-icon-boygirl';
$icon-icon-boygirl-x: 333px;
$icon-icon-boygirl-y: 0px;
$icon-icon-boygirl-offset-x: -333px;
$icon-icon-boygirl-offset-y: 0px;
$icon-icon-boygirl-width: 112px;
$icon-icon-boygirl-height: 124px;
$icon-icon-boygirl-total-width: 781px;
$icon-icon-boygirl-total-height: 709px;
$icon-icon-boygirl-image: '../images/icons/sprite.png';
$icon-icon-boygirl: (333px, 0px, -333px, 0px, 112px, 124px, 781px, 709px, '../images/icons/sprite.png', 'icon-icon-boygirl', );
$icon-igf-name: 'icon-igf';
$icon-igf-x: 448px;
$icon-igf-y: 585px;
$icon-igf-offset-x: -448px;
$icon-igf-offset-y: -585px;
$icon-igf-width: 112px;
$icon-igf-height: 124px;
$icon-igf-total-width: 781px;
$icon-igf-total-height: 709px;
$icon-igf-image: '../images/icons/sprite.png';
$icon-igf: (448px, 585px, -448px, -585px, 112px, 124px, 781px, 709px, '../images/icons/sprite.png', 'icon-igf', );
$icon-info-name: 'icon-info';
$icon-info-x: 333px;
$icon-info-y: 248px;
$icon-info-offset-x: -333px;
$icon-info-offset-y: -248px;
$icon-info-width: 52px;
$icon-info-height: 57px;
$icon-info-total-width: 781px;
$icon-info-total-height: 709px;
$icon-info-image: '../images/icons/sprite.png';
$icon-info: (333px, 248px, -333px, -248px, 52px, 57px, 781px, 709px, '../images/icons/sprite.png', 'icon-info', );
$icon-man-ruller-name: 'icon-man-ruller';
$icon-man-ruller-x: 560px;
$icon-man-ruller-y: 628px;
$icon-man-ruller-offset-x: -560px;
$icon-man-ruller-offset-y: -628px;
$icon-man-ruller-width: 30px;
$icon-man-ruller-height: 47px;
$icon-man-ruller-total-width: 781px;
$icon-man-ruller-total-height: 709px;
$icon-man-ruller-image: '../images/icons/sprite.png';
$icon-man-ruller: (560px, 628px, -560px, -628px, 30px, 47px, 781px, 709px, '../images/icons/sprite.png', 'icon-man-ruller', );
$icon-menu-close-name: 'icon-menu-close';
$icon-menu-close-x: 735px;
$icon-menu-close-y: 543px;
$icon-menu-close-offset-x: -735px;
$icon-menu-close-offset-y: -543px;
$icon-menu-close-width: 18px;
$icon-menu-close-height: 16px;
$icon-menu-close-total-width: 781px;
$icon-menu-close-total-height: 709px;
$icon-menu-close-image: '../images/icons/sprite.png';
$icon-menu-close: (735px, 543px, -735px, -543px, 18px, 16px, 781px, 709px, '../images/icons/sprite.png', 'icon-menu-close', );
$icon-menu-name: 'icon-menu';
$icon-menu-x: 753px;
$icon-menu-y: 543px;
$icon-menu-offset-x: -753px;
$icon-menu-offset-y: -543px;
$icon-menu-width: 18px;
$icon-menu-height: 16px;
$icon-menu-total-width: 781px;
$icon-menu-total-height: 709px;
$icon-menu-image: '../images/icons/sprite.png';
$icon-menu: (753px, 543px, -753px, -543px, 18px, 16px, 781px, 709px, '../images/icons/sprite.png', 'icon-menu', );
$icon-molecule-graph-name: 'icon-molecule-graph';
$icon-molecule-graph-x: 497px;
$icon-molecule-graph-y: 337px;
$icon-molecule-graph-offset-x: -497px;
$icon-molecule-graph-offset-y: -337px;
$icon-molecule-graph-width: 48px;
$icon-molecule-graph-height: 48px;
$icon-molecule-graph-total-width: 781px;
$icon-molecule-graph-total-height: 709px;
$icon-molecule-graph-image: '../images/icons/sprite.png';
$icon-molecule-graph: (497px, 337px, -497px, -337px, 48px, 48px, 781px, 709px, '../images/icons/sprite.png', 'icon-molecule-graph', );
$icon-molecule-name: 'icon-molecule';
$icon-molecule-x: 385px;
$icon-molecule-y: 248px;
$icon-molecule-offset-x: -385px;
$icon-molecule-offset-y: -248px;
$icon-molecule-width: 54px;
$icon-molecule-height: 54px;
$icon-molecule-total-width: 781px;
$icon-molecule-total-height: 709px;
$icon-molecule-image: '../images/icons/sprite.png';
$icon-molecule: (385px, 248px, -385px, -248px, 54px, 54px, 781px, 709px, '../images/icons/sprite.png', 'icon-molecule', );
$icon-moon-name: 'icon-moon';
$icon-moon-x: 607px;
$icon-moon-y: 461px;
$icon-moon-offset-x: -607px;
$icon-moon-offset-y: -461px;
$icon-moon-width: 47px;
$icon-moon-height: 45px;
$icon-moon-total-width: 781px;
$icon-moon-total-height: 709px;
$icon-moon-image: '../images/icons/sprite.png';
$icon-moon: (607px, 461px, -607px, -461px, 47px, 45px, 781px, 709px, '../images/icons/sprite.png', 'icon-moon', );
$icon-nature-name: 'icon-nature';
$icon-nature-x: 336px;
$icon-nature-y: 585px;
$icon-nature-offset-x: -336px;
$icon-nature-offset-y: -585px;
$icon-nature-width: 112px;
$icon-nature-height: 124px;
$icon-nature-total-width: 781px;
$icon-nature-total-height: 709px;
$icon-nature-image: '../images/icons/sprite.png';
$icon-nature: (336px, 585px, -336px, -585px, 112px, 124px, 781px, 709px, '../images/icons/sprite.png', 'icon-nature', );
$icon-needle-lg-name: 'icon-needle-lg';
$icon-needle-lg-x: 224px;
$icon-needle-lg-y: 585px;
$icon-needle-lg-offset-x: -224px;
$icon-needle-lg-offset-y: -585px;
$icon-needle-lg-width: 112px;
$icon-needle-lg-height: 124px;
$icon-needle-lg-total-width: 781px;
$icon-needle-lg-total-height: 709px;
$icon-needle-lg-image: '../images/icons/sprite.png';
$icon-needle-lg: (224px, 585px, -224px, -585px, 112px, 124px, 781px, 709px, '../images/icons/sprite.png', 'icon-needle-lg', );
$icon-notepad-name: 'icon-notepad';
$icon-notepad-x: 669px;
$icon-notepad-y: 372px;
$icon-notepad-offset-x: -669px;
$icon-notepad-offset-y: -372px;
$icon-notepad-width: 112px;
$icon-notepad-height: 124px;
$icon-notepad-total-width: 781px;
$icon-notepad-total-height: 709px;
$icon-notepad-image: '../images/icons/sprite.png';
$icon-notepad: (669px, 372px, -669px, -372px, 112px, 124px, 781px, 709px, '../images/icons/sprite.png', 'icon-notepad', );
$icon-num1-name: 'icon-num1';
$icon-num1-x: 669px;
$icon-num1-y: 0px;
$icon-num1-offset-x: -669px;
$icon-num1-offset-y: 0px;
$icon-num1-width: 112px;
$icon-num1-height: 124px;
$icon-num1-total-width: 781px;
$icon-num1-total-height: 709px;
$icon-num1-image: '../images/icons/sprite.png';
$icon-num1: (669px, 0px, -669px, 0px, 112px, 124px, 781px, 709px, '../images/icons/sprite.png', 'icon-num1', );
$icon-num2-name: 'icon-num2';
$icon-num2-x: 448px;
$icon-num2-y: 461px;
$icon-num2-offset-x: -448px;
$icon-num2-offset-y: -461px;
$icon-num2-width: 112px;
$icon-num2-height: 124px;
$icon-num2-total-width: 781px;
$icon-num2-total-height: 709px;
$icon-num2-image: '../images/icons/sprite.png';
$icon-num2: (448px, 461px, -448px, -461px, 112px, 124px, 781px, 709px, '../images/icons/sprite.png', 'icon-num2', );
$icon-num3-name: 'icon-num3';
$icon-num3-x: 224px;
$icon-num3-y: 461px;
$icon-num3-offset-x: -224px;
$icon-num3-offset-y: -461px;
$icon-num3-width: 112px;
$icon-num3-height: 124px;
$icon-num3-total-width: 781px;
$icon-num3-total-height: 709px;
$icon-num3-image: '../images/icons/sprite.png';
$icon-num3: (224px, 461px, -224px, -461px, 112px, 124px, 781px, 709px, '../images/icons/sprite.png', 'icon-num3', );
$icon-num5-name: 'icon-num5';
$icon-num5-x: 445px;
$icon-num5-y: 248px;
$icon-num5-offset-x: -445px;
$icon-num5-offset-y: -248px;
$icon-num5-width: 55px;
$icon-num5-height: 55px;
$icon-num5-total-width: 781px;
$icon-num5-total-height: 709px;
$icon-num5-image: '../images/icons/sprite.png';
$icon-num5: (445px, 248px, -445px, -248px, 55px, 55px, 781px, 709px, '../images/icons/sprite.png', 'icon-num5', );
$icon-parrents-name: 'icon-parrents';
$icon-parrents-x: 0px;
$icon-parrents-y: 0px;
$icon-parrents-offset-x: 0px;
$icon-parrents-offset-y: 0px;
$icon-parrents-width: 333px;
$icon-parrents-height: 337px;
$icon-parrents-total-width: 781px;
$icon-parrents-total-height: 709px;
$icon-parrents-image: '../images/icons/sprite.png';
$icon-parrents: (0px, 0px, 0px, 0px, 333px, 337px, 781px, 709px, '../images/icons/sprite.png', 'icon-parrents', );
$icon-pediatr-name: 'icon-pediatr';
$icon-pediatr-x: 224px;
$icon-pediatr-y: 337px;
$icon-pediatr-offset-x: -224px;
$icon-pediatr-offset-y: -337px;
$icon-pediatr-width: 112px;
$icon-pediatr-height: 124px;
$icon-pediatr-total-width: 781px;
$icon-pediatr-total-height: 709px;
$icon-pediatr-image: '../images/icons/sprite.png';
$icon-pediatr: (224px, 337px, -224px, -337px, 112px, 124px, 781px, 709px, '../images/icons/sprite.png', 'icon-pediatr', );
$icon-pen-lg-name: 'icon-pen-lg';
$icon-pen-lg-x: 0px;
$icon-pen-lg-y: 337px;
$icon-pen-lg-offset-x: 0px;
$icon-pen-lg-offset-y: -337px;
$icon-pen-lg-width: 112px;
$icon-pen-lg-height: 124px;
$icon-pen-lg-total-width: 781px;
$icon-pen-lg-total-height: 709px;
$icon-pen-lg-image: '../images/icons/sprite.png';
$icon-pen-lg: (0px, 337px, 0px, -337px, 112px, 124px, 781px, 709px, '../images/icons/sprite.png', 'icon-pen-lg', );
$icon-pills-name: 'icon-pills';
$icon-pills-x: 448px;
$icon-pills-y: 392px;
$icon-pills-offset-x: -448px;
$icon-pills-offset-y: -392px;
$icon-pills-width: 48px;
$icon-pills-height: 48px;
$icon-pills-total-width: 781px;
$icon-pills-total-height: 709px;
$icon-pills-image: '../images/icons/sprite.png';
$icon-pills: (448px, 392px, -448px, -392px, 48px, 48px, 781px, 709px, '../images/icons/sprite.png', 'icon-pills', );
$icon-ruler-name: 'icon-ruler';
$icon-ruler-x: 618px;
$icon-ruler-y: 372px;
$icon-ruler-offset-x: -618px;
$icon-ruler-offset-y: -372px;
$icon-ruler-width: 48px;
$icon-ruler-height: 48px;
$icon-ruler-total-width: 781px;
$icon-ruler-total-height: 709px;
$icon-ruler-image: '../images/icons/sprite.png';
$icon-ruler: (618px, 372px, -618px, -372px, 48px, 48px, 781px, 709px, '../images/icons/sprite.png', 'icon-ruler', );
$icon-sex-name: 'icon-sex';
$icon-sex-x: 445px;
$icon-sex-y: 124px;
$icon-sex-offset-x: -445px;
$icon-sex-offset-y: -124px;
$icon-sex-width: 112px;
$icon-sex-height: 124px;
$icon-sex-total-width: 781px;
$icon-sex-total-height: 709px;
$icon-sex-image: '../images/icons/sprite.png';
$icon-sex: (445px, 124px, -445px, -124px, 112px, 124px, 781px, 709px, '../images/icons/sprite.png', 'icon-sex', );
$icon-stage-1-name: 'icon-stage-1';
$icon-stage-1-x: 445px;
$icon-stage-1-y: 0px;
$icon-stage-1-offset-x: -445px;
$icon-stage-1-offset-y: 0px;
$icon-stage-1-width: 112px;
$icon-stage-1-height: 124px;
$icon-stage-1-total-width: 781px;
$icon-stage-1-total-height: 709px;
$icon-stage-1-image: '../images/icons/sprite.png';
$icon-stage-1: (445px, 0px, -445px, 0px, 112px, 124px, 781px, 709px, '../images/icons/sprite.png', 'icon-stage-1', );
$icon-stage-2-name: 'icon-stage-2';
$icon-stage-2-x: 333px;
$icon-stage-2-y: 124px;
$icon-stage-2-offset-x: -333px;
$icon-stage-2-offset-y: -124px;
$icon-stage-2-width: 112px;
$icon-stage-2-height: 124px;
$icon-stage-2-total-width: 781px;
$icon-stage-2-total-height: 709px;
$icon-stage-2-image: '../images/icons/sprite.png';
$icon-stage-2: (333px, 124px, -333px, -124px, 112px, 124px, 781px, 709px, '../images/icons/sprite.png', 'icon-stage-2', );
$icon-stage-3-name: 'icon-stage-3';
$icon-stage-3-x: 0px;
$icon-stage-3-y: 461px;
$icon-stage-3-offset-x: 0px;
$icon-stage-3-offset-y: -461px;
$icon-stage-3-width: 112px;
$icon-stage-3-height: 124px;
$icon-stage-3-total-width: 781px;
$icon-stage-3-total-height: 709px;
$icon-stage-3-image: '../images/icons/sprite.png';
$icon-stage-3: (0px, 461px, 0px, -461px, 112px, 124px, 781px, 709px, '../images/icons/sprite.png', 'icon-stage-3', );
$spritesheet-width: 781px;
$spritesheet-height: 709px;
$spritesheet-image: '../images/icons/sprite.png';
$spritesheet-sprites: ($icon-18, $icon-accordion, $icon-active-tab, $icon-arrow-down-blue, $icon-arrow-down, $icon-arrow-right, $icon-babyheart, $icon-ball, $icon-bike, $icon-bone, $icon-book-lg, $icon-boy, $icon-brain-small, $icon-brain, $icon-calendar, $icon-calendar2, $icon-check-pink, $icon-checklist, $icon-clock, $icon-food, $icon-growth-men, $icon-hand-lg, $icon-heart-plus, $icon-heart, $icon-icon-boygirl, $icon-igf, $icon-info, $icon-man-ruller, $icon-menu-close, $icon-menu, $icon-molecule-graph, $icon-molecule, $icon-moon, $icon-nature, $icon-needle-lg, $icon-notepad, $icon-num1, $icon-num2, $icon-num3, $icon-num5, $icon-parrents, $icon-pediatr, $icon-pen-lg, $icon-pills, $icon-ruler, $icon-sex, $icon-stage-1, $icon-stage-2, $icon-stage-3, );
$spritesheet: (781px, 709px, '../images/icons/sprite.png', $spritesheet-sprites, );

// The provided mixins are intended to be used with the array-like variables
//
// .icon-home {
//   @include sprite-width($icon-home);
// }
//
// .icon-email {
//   @include sprite($icon-email);
// }
//
// Example usage in HTML:
//
// `display: block` sprite:
// <div class="icon-home"></div>
//
// To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:
//
// // CSS
// .icon {
//   display: inline-block;
// }
//
// // HTML
// <i class="icon icon-home"></i>
@mixin sprite-width($sprite) {
  width: nth($sprite, 5);
}

@mixin sprite-height($sprite) {
  height: nth($sprite, 6);
}

@mixin sprite-position($sprite) {
  $sprite-offset-x: nth($sprite, 3);
  $sprite-offset-y: nth($sprite, 4);
  background-position: $sprite-offset-x  $sprite-offset-y;
}

@mixin sprite-image($sprite) {
  $sprite-image: nth($sprite, 9);
  background-image: url(#{$sprite-image});
}

@mixin sprite($sprite) {
  @include sprite-image($sprite);
  @include sprite-position($sprite);
  @include sprite-width($sprite);
  @include sprite-height($sprite);
}

// The `sprites` mixin generates identical output to the CSS template
//   but can be overridden inside of SCSS
//
// @include sprites($spritesheet-sprites);
@mixin sprites($sprites) {
  @each $sprite in $sprites {
    $sprite-name: nth($sprite, 10);
    .#{$sprite-name} {
      @include sprite($sprite);
    }
  }
}
