
	.navbar-toggle {
		display: none;

        @media only screen and (max-width: 768px) {
			display: block;
			@include center(y);
			right: 15px;
        }
		
		button {
			display: block;
			background: $blue-base;
			border: 0;
			cursor: pointer;
			color: $light-base;
			font-family: $font-family-base-medium;
			font-size: $font-size-sm;
			line-height: 40px;
			text-transform: uppercase;
			padding: 0 10px 0 40px;
			height: 40px;
		}

		.icon-menu {
			display: block;
			vertical-align: middle;
			@include sprite($icon-menu);
			@include center(y);
			left: 10px;
			margin-right: $gap - 5px;
		}

		&.is-active {

			.icon-menu {
				@include sprite($icon-menu-close);
			}

		}

	}
