
	html {
		background-color: $html-bg;
		box-sizing: border-box;
	}

	body {
		-webkit-font-smoothing: subpixel-antialiased;
		font-family: $font-family-base;
		font-size: $font-size-base;
		line-height: $line-height-base;
		color: $text-color;
		background-color: $body-bg;
		overflow-x: hidden;
	}

	a {
		color: $link-color;
		text-decoration: underline;

		&:hover,
		&:focus {
			color: $link-color-hover;
			text-decoration: none;
		}

	}

	* {
		box-sizing: border-box;
	}

	.container-fluid {
		max-width: 1080px;
        &.px-0{
            padding: 0px;
        }
	}


	.orange{
            color: $orange-text;
     }

     .text-center{
     	text-align: center;
     }
     .text-sm-left{
     	@include screen-sm {

     		text-align: left;
     	}
     }


     .bg-white{
     	background-color: white;
     	padding: $gap;
     }
		 .pink{

					 color:$pink-base;

		 }
