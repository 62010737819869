.block-info{

	background: white;
	padding: $gap;
	padding-right: $gap*3;
	margin-top: $gap;

	@include screen-md {


                    padding-right: 60px;
                    background-image: url(#{$bg-url}icon-info.png) ;
                    background-repeat: no-repeat;
                	  background-position: 95% 5%;
                	  margin-top: 0px;


            }

						p,strong,li{
							font-size:14px;
						}

						ul{
							margin-top:0px;
							li{
								font-weight: 900;
							}
						}



}
