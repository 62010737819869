
    .subpage {
        background-color: $subpage-bg;
        padding-top: 100px;

        @include screen-sm {
            background: $subpage-bg url(#{$bg-url}subpage-bg.png) no-repeat 50% 100%;
            background-size: 100% auto;
            padding-bottom: 100px;
            padding-top: $gap*12;
        }

    }