
	.hp-section-main-heading,
	.main-heading {
		font-size: $font-size-lg;
		font-family: $font-family-heading-bold;
		margin: 0 0 $gap;
		line-height: $line-height-base;
		color: $light-base;

		@include screen-sm {
			font-size: $font-size-xl;
		}
	}

	.main-heading {
		font-size: $font-size-lg - 10px;
		text-align: center;
		color: $pink-base;
		
		span {
			display: inline-block;
			background: url(#{$bg-url}main-heading-underline.png) no-repeat 50% 100%;
			background-size: 100% auto;
			padding-bottom: $gap - 5px;
		}

		@include screen-sm {
			font-size: $font-size-lg;
		}

	}

	h1,
	h2,
	h3 {
		line-height: $line-height-base + 20%;
		b{
			font-family: $font-family-base-medium;
		}
	}

	h2 {
		font-family: $font-family-base-bold;
		text-transform: uppercase;
		margin: $gap 0;
		font-size: $font-size-md;
	}

	h3 {
		font-size: $font-size-sm + 2px;
		margin-bottom: $gap;
	}

	p + h2 {
		margin-top: $gap*2;
	}
	.note{
		
		font-size: $font-size-note;
		
	}

	p,
	ul,
	ol,
	address,
	table {
		font-size: $font-size-sm;
		line-height: $line-height-base + 40%;

		b,
		strong {
			font-family: $font-family-base-bold;
		}

		small {
			font-size: $font-size-base;
		}
	}