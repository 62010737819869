
	.primary-nav {
        text-transform: uppercase;
        font-size: $font-size-sm;
        font-family: $font-family-base-bold;
        @extend %unstyled-list;

        @include screen-sm {
            padding-bottom: $gap;
            font-size: $font-size-sm;

            li {
                display: inline-block;

                & + li {
                    margin-left: $gap;
                }
            }

        }

        @include screen-md {
             font-size: $font-size-md;
        }

        li {

            @media only screen and (max-width: 768px) {

                & + li {
                    border-top: 1px solid $gray-light;
                }   

            }

        }

        a {
            color: $text-color;
            text-decoration: none;

            &:hover,
            &.is-active {
                color: $blue-base;
            }

            @media only screen and (max-width: 768px) {
                display: block;
                padding: $gap - 5px $gap;
            }

        }


	
	}



