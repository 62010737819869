
	.button {
		transition: all 0.2s ease;
		line-height: $line-height-base;
		font-size: $font-size-sm;
		display: block;
		width: 100%;
		font-family: $font-family-base-medium;
		text-decoration: none;
		outline: 0 none;
		text-transform: uppercase;
		text-align: center;
		padding: $gap;
		position: relative;


		&.with-icon {
			padding-right: $gap*5;
			padding-left: $gap+10;
		}

		.fa {
			font-size: 38px;
			@include center(y);
			right: 25px;

		}

		@include screen-xs {
			min-width: 216px;
			display: inline-block;
			width: auto;
		}

		@include screen-sm {
			font-size: $font-size-sm + 2px;
			padding: $gap $gap $gap - 4px;
			padding-right: $gap+10;
			padding-left: $gap+10; // smaller bottom padding because of font render on desktop which takes more bottom space if included via font-face
		}

		/*	Default
		-------------------------------------------------------------------------- */

		&--default {
			background-color: $pink-base;
			border-radius:  $button-border-radius;
			border: 2px solid $pink-base;
			color: $light-base;

			.fa {
				color: $light-base;
			}

			&:hover {
				background-color: $light-base;
				color: $pink-base;

				.fa {
					color: $pink-base;
				}

			}

		}

		/*	Alternative
		-------------------------------------------------------------------------- */

		&--alt {
			background-color: $blue-base;
			border-radius:  $button-border-radius;
			border: 2px solid $blue-base;
			color: $light-base;

			.fa {
				color: $light-base;
			}

			&:hover {
				background-color: $light-base;
				color: $blue-base;

				.fa {
					color: $blue-base;
				}
			}

		}

	}

	button[disabled] {
		cursor: default;
	}

	.btn-group {
		//text-align: center;
		margin-bottom: $gap*2;
		.button {
			margin: $gap - 5px 0;
		}

		@include screen-xs {

			.button {
				margin: $gap - 8px $gap - 8px;
			}

		}
	}

	.center{
		text-align:center;
		.button {
			margin-left: auto;
			margin-right: auto;
			margin-top: $gap;
		}
	}
