	
	%responsive-image {
		max-width: 100%;
		height: auto;
	}

	%center-block {
	    display: block;
	    margin-left: auto;
	    margin-right: auto;
	}

	%unstyled-list {
		list-style-type: none;
		padding: 0;
		margin: 0;
	}
