.m{

	&-auto{
		margin: 0 auto;    
		display: block;
	}

	&b{
		&-0{
			margin-bottom:0px;
		}
		&-3{
			margin-bottom:1.5rem;
		}
		&-gap{
			margin-bottom: $gap;
		}
	}
	&t{
		&-0{
			margin-top:0px;

		}
		&-3{
			margin-top: 1.5rem;
		}

		&-gap{
			margin-top: $gap;
		}
	}
	&y{

		&-gap{
			margin-top: $gap*3;
			margin-bottom: $gap*3;
		}

	}
	
}

.p{
	&b{
		&-0{
			padding-bottom:0px;
		}
		&-3{
			padding-bottom:1.5rem;
		}
		&-gap{
			padding-bottom: $gap;
		}
	}
	&t{
		&-0{
			padding-top:0px;
		}
		&-gap{
			padding-top: $gap;
		}
	}
}
