
	input,
	button,
	textarea {
		font-family: inherit;
		font-size: inherit;
		line-height: inherit;
		outline: 0;
	  	-webkit-tap-highlight-color: rgba(0,0,0,0);
	  	-webkit-appearance: none;
	}

	::-ms-reveal,
	::-ms-clear {
		display: none !important;
	}

	textarea {
		resize: none;
	}

	input[type="text"],
	input[type="password"],
	input[type="number"],
	textarea {

		box-shadow: none;
		border: 1px solid $blue-base;

		&:hover,
		&:active {

		}

		&::-webkit-input-placeholder {

		}

		&:-moz-placeholder {

			opacity: 1;
		}

		&::-moz-placeholder {

			opacity: 1;
		}

		&:-ms-input-placeholder {

		}

		&:-ms-input-placeholder {

		}

	}


	.calc-form,.contact-form{
		background: $light-base;
		padding: $gap + 5;
		margin-bottom: $gap * 2;
		h3{
			text-align:center;
			font-size: $font-size-md;
			padding: $gap;
			font-family: $font-family-base-bold;
		}

		.growth-stages{
			margin-top:0px;
		}
		.content{
			margin: 0 auto !important;

			.row{

				max-width: 100%;
    			margin: 0 auto;
    			margin-bottom: 20px;
			}

			.content-input{
				width: calc( 100%);
				float:left;
				margin-top:10px;
			}


			label{
				    display: inline-block;
				    float:left;
				    position: relative;
				    padding-left: 35px;
				    margin-bottom: 12px;
				    cursor: pointer;
				    font-size: 17px;
				    line-height: 140%;
    				height: 25px;
				    -webkit-user-select: none;
				    -moz-user-select: none;
				    -ms-user-select: none;
				    user-select: none;
				    padding-right:10px;
				    margin-top:5px;

                    &.check{
                        height: auto;

                    }


				    input{
				    	  position: absolute;
						  opacity: 0;
						  cursor: pointer;
						  height: 0;
						  width: 0;

    					&:checked  ~ span:after{
    						    display: block;
    					}

    					&:checked  ~ span{

    					}
				    }

				    &:hover{
				    	input ~ span{


				    		&:after{
				    			display:block;
				    		}
				    	}
				    }

				    span{
				    	position: absolute;
					    top: 0;
					    left: 0;
					    height: 25px;
					    width: 25px;
					    background-color: $light-base;
					    border: 1px solid $blue-base;
					    border-radius: 50%;

                        &.checkbox{
                        border-radius: 0;

                         &::after {

                        border-radius: 0;
                         }
                        }

					    &::after {
					    	content: "";
						    position: absolute;
						    display: none;
						    top: 7px;
						    left: 7px;
						    width: 9px;
						    height: 9px;
						    border-radius: 50%;
						    background: $blue-base;
						}
				    }
			}
			.text-l,p{
				min-width: 100%;
				font-size:$font-size-sm;
				text-align: left;
				line-height: 190%;
				display: inline-block;
				margin:0px;
				float:left;

                &.quest-form{
                    label{
                        padding-left:0px;
                        margin-left: 0px;
                    }

                }
			}

			input{
				width: calc( 100%);
				font-size:$font-size-sm;
				padding: $gap - 10px;
				margin-top:10px;
				margin-bottom: 10px;
				height:40px;
				float:left;
				display: inline-block;


			}
            textarea{
                width: calc( 100%);
                padding: $gap - 10px;
				margin-top:10px;
				margin-bottom: 10px;
                font-size:$font-size-sm;
                float:left;
				display: inline-block;
            }
			@include screen-sm {
				max-width: 75%;
                &.full{
                    max-width: 100%;
                }
				.row{
					margin-bottom: 10px;
				}
				.text-l,p{
				min-width: 160px;
				}
				input{
					width: calc( 100% - 171px);
					margin-left: 10px;
					margin-right: 0px;
					margin-bottom:0px;
					margin-top:0px;

                    &.calc{
                        width: calc( 100% - 330px);
                        margin-right: 10px;
                    }


				}
                textarea{
                    width: calc( 100% - 171px);
					margin-left: 10px;
					margin-right: 0px;
					margin-bottom:0px;
					margin-top:0px;

                    &.calc{
                        width: calc( 100% - 230px);
                        margin-right: 10px;
                    }

                }
				.content-input{
				width: calc( 100% - 200px);
				margin-left: 10px;
				margin-right:10px;
				margin-top:0px;

                &.quest-input{
                    margin-top:0px;
                    margin-bottom: 0px;
                }

                &.check-content{
                    width: 100%;
                    margin-left:0px;
                }

				}
			}



		}
	}
