
	.list-arrow{


		ul {
			padding-left:0px;
			list-style-type: none;
			li{
				padding-left: 25px;
				padding-bottom:10px;
				position:relative;
				&:before{
					content:"";
					position:absolute;
					display: inline-block;
    				margin-right: 10px;
    				left:0px;
    				top:5px;
					@include sprite($icon-arrow-right);
				}
			}


		}

	}

	ul{

		&.blue-list{


					list-style-type:none;
					padding-left:0px;

					li{
						position: relative;
    					padding-left: 19px;
								padding-bottom: 10px;

								&:last-of-type{
									padding-bottom: 0px;
								}
						&:before{
							content: "";
						    width: 10px;
						    height: 10px;
						    position: absolute;
						    top: 7px;
						    border-radius: 50%;
						    background: $blue-base;
						    left: 0px;

						}

					}
				}

				&.advantages{
					list-style-type:none;
					padding-left:0px;

					&.bg-injection{
						padding-right: 50px;
						background: transparent url(#{$bg-url}injection.png) no-repeat 100% 100%;
						background-size:contain;
					}
					&.bg-injection2{
						padding-right: 50px;
						background: transparent url(#{$bg-url}injection2.png) no-repeat 100% 100%;
						background-size:contain;
					}

					li{
						padding-bottom: $gap;
						span{
							font-weight:bold;
							padding-right:5px;
							&.pink{
								color:$pink-base;

							}
							&.blue{
								color:$blue-base;

							}
						}
					}
				}
			}
