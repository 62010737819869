.answers {
    background: $light-base;
    padding: $gap + 5;
    margin-bottom: $gap * 2;

    &.p-0{
        padding: 0px;
    }
    &.m-0{
        margin: 0px;
    }

    .answer {
        border-bottom: 1px solid $subpage-bg;
        padding-bottom: $gap + 5;

        &:last-of-type {
            border-bottom: none;
        }

        &:not(:first-type-of-element) {
            padding-top: $gap + 5;
        }

        .title {
            span {
                font-family: "quicksandbold",sans-serif;
                color: $blue-base;
                font-weight: bold;
            }
        }

        h2 {
            font-size: $font-size-md;
            font-family: $font-family-base-bold;
            margin: 0 0 $gap - 5px;
            text-transform: none;
        }

        h3 {
            color: $orange-base;
            font-size: $font-size-md;
            font-family: $font-family-base-bold;
            margin: 0 0 $gap - 5px;
            text-transform: none;
        }

        a {
            font-size: $font-size-note;
        }
    }

    .pagination {
        text-align: center;
        padding-top: $gap + 5;
        padding-bottom: $gap + 5;

        a,span {
            font-size: $font-size-note;
            padding-left: $gap - 5;
            padding-right: $gap - 5;


            &.active {
                color: $blue-base;
            }
        }
        span{
            color: $blue-base;
        }

        ul{
            margin: 0 auto !important;
            padding: 0;
        }
    }
}

.doc-block{
    display: flex;
    flex-flow: column;
    align-items: center;

    @media screen and (min-width: 768px){
        flex-flow: row;
    }

    img{

        text-align: center;
        height: 90px;
        margin-bottom: 25px;


        @media screen and (min-width: 768px){
            text-align: left;
            margin-bottom:0px;
            margin-right: 30px;
        }
    }
}
