
	@mixin screen-lg {
		@media only screen and (min-width: 1200px) {
			@content;
		}
	}

	@mixin screen-md {
		@media only screen and (min-width: 992px) {
			@content;
		}
	}

	@mixin screen-sm {
		@media only screen and (min-width: 768px) {
			@content;
		}
	}

	@mixin screen-xs {
		@media only screen and (min-width: 480px) {
			@content;
		}
	}

	@mixin clearfix() {
		&:before,
		&:after {
			content: "";
			display: table;
		}
		&:after {
			clear: both;
		}
	}

	@mixin center ($position) {
		position: absolute;

		@if $position == 'x' {
			left: 50%;
			-webkit-transform: translateX(-50%);
				-ms-transform: translate(-50%);
					transform: translate(-50%);
		}

		@else if $position == 'y' {
			top: 50%;
			-webkit-transform: translateY(-50%);
				-ms-transform: translateY(-50%);
					transform: translateY(-50%);
		}

		@else if $position == 'xy' {
			top: 50%;
			left: 50%;
			-webkit-transform: translate(-50%, -50%);
				-ms-transform: translate(-50%, -50%);
					transform: translate(-50%, -50%);
		}

	}

	@mixin no-transform ($argument) {
		-webkit-transform: none;
				transform: none;
	}

	@mixin transform ($transforms) {
		-webkit-transform: 	$transforms;
		 	-ms-transform: 	$transforms;
				transform: 	$transforms;
	}

	@mixin rotate ($deg) {
		@include transform(rotate(#{$deg}deg));
	}

	@mixin scale ($scale) {
		@include transform(scale($scale));
	} 

	@mixin translate ($x, $y) {
		@include transform(translate($x, $y));
	}

	@mixin animation ($name, $duration, $delay, $ease) {
		-webkit-animation: 	$name $duration $delay $ease;
				animation:  $name $duration $delay $ease;
	}