
    [class*="col"].sidebar {
        position: static; // fix for js sticky plugin, otherwise sticky element will disappear when reaching bottom of the parent container
    }

    .sidebar {
        margin-top: $gap * 4;
        padding-bottom: $gap * 4;
        position: static;

        &.is-sticky {
            //position: fixed !important;
        }

        @include screen-md {
            margin-top: 0;
        }

        .boxes {
            background: $light-base;
            border-radius: 5px;
            overflow: hidden;

            &:nth-child(1n+2){
                margin-top: $gap + 5px;
            }
        }

        .boxes__heading {
            background: $pink-base;
            color: $light-base;
            font-size: $font-size-md;
            margin: 0;
            padding: $gap + 5px $gap;
            text-transform: none;
            text-align: center; 
        }

        .boxes__content {
            padding: $gap + 5px;
        }

        .box-aside {

            p {
                margin: $gap - 5px 0;
            }

            a {
                color: $pink-base;
            }

            & + .box-aside {
                margin-top: $gap * 2;
            }

            &:last-of-type {
                margin-bottom: 0px;
                & + img{
                    margin-top: $gap * 2;
                }
            }

            &--noneedle {

            }

            p.doctor-bg,
            p.noneedle-bg {
                background: none;
                padding-right: 0;
            }

            @include screen-md {

                p.doctor-bg {
                    padding-right: 100px;
                    background: transparent url(#{$bg-url}doctor-bg.png) no-repeat 100% 100%;  
                }

                p.noneedle-bg {
                    padding-right: 80px;
                    background: transparent url(#{$bg-url}noneedle-bg.png) no-repeat 100% 100%;  
                }

            }

            &.with-icon {
                 text-align:center;
                 padding-top: 60px;
                 position:relative;

                 &:before {
                    display: block;
                    content: "";
                    @include center(x);
                    top: 0;
                 }
            }

            &.icon-ruler {

                &:before {
                    @include sprite($icon-ruler);
                }

            }

            &.icon-heart {

                &:before {
                    @include sprite($icon-heart);
                }

            }

            &.icon-molecule {

                &:before {
                    @include sprite($icon-molecule);
                }

            }

            &.icon-pills {

                &:before {
                    @include sprite($icon-pills);
                }

            }

            &.icon-brain {

                &:before {
                    @include sprite($icon-brain-small);
                }

            }

            &.icon-18 {

                &:before {
                    @include sprite($icon-18);
                }

            }

            &.icon-num5 {

                &:before {
                    @include sprite($icon-num5);
                }

            }

            &.icon-molecule-graph {

                &:before {
                    @include sprite($icon-molecule-graph);
                }

            }

             &.icon-calendar {

                &:before {
                    @include sprite($icon-calendar);
                }

            }

            &.icon-form {

                &:before {
                    @include sprite($icon-checklist);
                }

            }

            &.icon-growth-man {

                &:before {
                    @include sprite($icon-growth-men);
                }

            }
          
            &.icon-moon {

                &:before {
                    @include sprite($icon-moon);
                }

            }
            &.icon-ball {

                &:before {
                    @include sprite($icon-ball);
                }

            }
            &.icon-man-ruller {

                &:before {
                    @include sprite($icon-man-ruller);
                }

            }


        }



        .box-aside__heading {
            font-size: $font-size-md - 2px;
            margin: 0 0 $gap;
            font-family: $font-family-base-bold;
        }

    }