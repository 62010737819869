
	.navbar {
        display: none;
        position: absolute;
        top: 100%;
        right: 0;
        width: 100%;

        @media only screen and (max-width: 768px) {
           background-color: $light-base;
           box-shadow: 0 5px 10px 0 rgba(0,0,0,0.1);
           padding-bottom: $gap;
        }

       @media only screen and (min-width: 769px) {
            display: block !important;
            background: url(#{$bg-url}navbar-bg.png) no-repeat 50% 100%;
            @include center(y);
            right: 0;
            padding: 0 $gap*2;
            width: auto;
        }

        &.is-active {
            display: block;
        }

	}



