
	.site-logo {
        width: 180px;
        transition: width .3s ease;

        @include screen-xs {
            width: 240px;
        }

        @include screen-md {
           width: 100%;
        }

        .is-sticky & {
            width: 180px;
        }



	}